/* ./src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
body {
  margin: 0;
  font-family: -apple-system, 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

}


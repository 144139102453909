@import url('https://fonts.googleapis.com/css?family=Roboto:700');


.containerAnimation {
  color:#070C08;
  text-transform: uppercase;
  width:100%;
  bottom:45%;
}

.flip {
  height:50px;
  overflow:hidden;
}

.flip > div > div {
  color:#fff;
  margin-top: 5px;
  padding:4px 12px;
  height:50px;
  margin-bottom:45px;
  display:inline-block;
}

.flip div:first-child {
  animation: show 6s linear infinite;
}

.flip div div {
  background:#676767;
}
.flip div:first-child div {
  background:#343434;
}
.flip div:last-child div {
  background:#111111;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  33% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  71% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}
